import { template as template_c4e576d2558e42228eef0aa6b782f0c3 } from "@ember/template-compiler";
const FKLabel = template_c4e576d2558e42228eef0aa6b782f0c3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
