import { template as template_0efcf5bc56c640a9b7ed4ff25e3adc3b } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
import concatClass from "discourse/helpers/concat-class";
import number from "discourse/helpers/number";
const ViewsCell = template_0efcf5bc56c640a9b7ed4ff25e3adc3b(`
  <td class={{concatClass "num views topic-list-data" @topic.viewsHeat}}>
    <PluginOutlet
      @name="topic-list-before-view-count"
      @outletArgs={{hash topic=@topic}}
    />
    {{number @topic.views numberKey="views_long"}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
