import { template as template_317b9e8f5eb04b03a4a242ebc6f15d24 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_317b9e8f5eb04b03a4a242ebc6f15d24(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
