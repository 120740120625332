import { template as template_fca6ee31dfdd48739b8d3d69c2995f66 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fca6ee31dfdd48739b8d3d69c2995f66(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
